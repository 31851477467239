export const TiposDePessoa = [
  {
    label: 'Física',
    value: 0,
  },
  {
    label: 'Jurídica',
    value: 1,
  },
];
