import * as yup from 'yup';
import { validaCPF, validarCNPJ } from '~/utils/functions';

export const schema = yup
  .object({
    num_cpf: yup.string().when('tipoPessoa', {
      is: (value: any) => {
        if (value === 0) return true;
        return false;
      },
      then: yup.string().notRequired(),
      otherwise: yup.string().notRequired().nullable(true),
    }),
    num_cnpj: yup.string().when('tipoPessoa', {
      is: (value: any) => {
        if (value === 1) return true;
        return false;
      },
      then: yup.string().notRequired(),
      // .test((value) => {
      //   // Remove os caracteres especiais e verifica possui 11 ou 14 digitos
      //   value = value?.replace(/\D/g, '');
      //   return value?.length === 14;
      // }),
      otherwise: yup.string().notRequired().nullable(true),
    }),
    num_ie: yup.string().notRequired(),
    // num_ie: yup
    //   .string()
    //   .notRequired()
    //   .when(['tipoPessoa'], {
    //     is: (valuePessoa: any) => {
    //       if (valuePessoa === 1) return false;
    //       return true;
    //     },
    //     then: yup.string().notRequired().nullable(true),
    //     otherwise: yup
    //       .string()
    //       .required()
    //       .test(function (value) {
    //         // chama funcao para validar IE com base no estado escolhido
    //         const estados = this.parent?.estados;

    //         if (!value) return false;

    //         if (!estados) {
    //           return true;
    //         }
    //         if (estados) {
    //           if (!estados.label) return true;
    //           return validar(value, estados.label);
    //         }

    //         return false;
    //       }),
    //   }),
    sexo: yup.object().when('tipoPessoa', {
      is: (value: any) => {
        if (value === 0) return true;
        return false;
      },
      then: yup.object().shape({
        label: yup.string().required(),
        value: yup.number().required(),
      }),
      otherwise: yup.object().notRequired().nullable(true),
    }),
    inativo: yup.boolean(),
    name: yup.string().required(),
    cep: yup.string().required(),
    logradouro: yup.string().required(),
    numero: yup.string().required(),
    bairro: yup.string().required(),
    cidades: yup
      .object()
      .shape({
        value: yup.number().required(),
        ufValue: yup.string().notRequired(),
        label: yup.string().required(),
      })
      .required(),
    flg_fabricante: yup.boolean(),
    flg_exportador: yup.boolean(),
    flg_regime_st: yup.boolean(),
    flg_regime_mei: yup.boolean(),
    flg_substituto_trib: yup.boolean(),
    flg_optante_simples: yup.boolean(),
    flg_calc_frete_val_bruto: yup.boolean(),
    flg_calc_indeniz_val_bruto: yup.boolean(),
    flg_calc_desp_ac_val_bruto: yup.boolean(),
    flg_calc_bonif_val_bruto: yup.boolean(),
    flg_calc_acresc_val_bruto: yup.boolean(),
    flg_calc_ipi_val_bruto: yup.boolean(),
    flg_rateia_icms_custo: yup.boolean(),
    flg_nao_desc_bc_st: yup.boolean().nullable(false),
    flg_nao_transf_icms: yup.boolean().nullable(false),
    flg_nao_transf_pis_cofins: yup.boolean(),
    flg_nao_cred_presumido: yup.boolean(),
    flg_produtor_rural: yup.boolean(),
    tipo_produtor: yup.number().when('flg_produtor_rural', {
      is: (value: any) => {
        if (value) return true;
        return false;
      },
      then: yup.number().required(),
      otherwise: yup.number().notRequired().nullable(true),
    }),
    produtor_rural_num_cpf: yup
      .string()
      .when(['flg_produtor_rural', 'tipo_produtor'], {
        is: (flgProdutorRural: boolean, tipoProdutor: number) => {
          return flgProdutorRural && tipoProdutor === 0;
        },
        then: yup
          .string()
          .required(
            'O campo produtor_rural_num_cpf é obrigatório quando flg_produtor_rural é true e tipo_produtor é 0.',
          )
          .test('valida-cpf', 'CPF inválido', (value) => {
            // Remove os caracteres especiais e verifica se possui 11 ou 14 dígitos
            return value ? validaCPF(value) : false;
          }),
        otherwise: yup.string().notRequired().nullable(true),
      }),
    produtor_rural_num_cnpj: yup
      .string()
      .when(['flg_produtor_rural', 'tipo_produtor'], {
        is: (flgProdutorRural: boolean, tipoProdutor: number) => {
          return flgProdutorRural && tipoProdutor === 1;
        },
        then: yup
          .string()
          .required(
            'O campo produtor_rural_num_cnpj é obrigatório quando flg_produtor_rural é true e tipo_produtor é 1.',
          )
          .test('valida-cnpj', 'CNPJ inválido', (value) => {
            // Remove os caracteres especiais e verifica se possui 11 ou 14 dígitos
            return value ? validarCNPJ(value) : false;
          }),
        otherwise: yup.string().notRequired().nullable(true),
      }),

    flg_suframa: yup.boolean(),
    flg_pauta: yup.boolean(),
    flg_aceita_devol_merc: yup.boolean(),
    num_crc: yup.string().when(['flg_contabilista'], {
      is: (flgContabilista: boolean) => {
        return flgContabilista;
      },
      then: yup
        .string()
        .required(
          'O campo num_crc é obrigatório quando flg_contabilista é true.',
        ),
      otherwise: yup.string().notRequired().nullable(true),
    }),
  })
  .required();

export const condicao = yup
  .object({
    condicaoFinalizadora: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    condicao: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    numCondicao: yup.number().required(),
  })
  .required();
