export const Default = {
  sexo: [
    {
      label: 'Masculino',
      value: 0,
    },
    {
      label: 'Feminino',
      value: 1,
    },
  ],
  ativo: [
    {
      label: 'Ativo',
      value: 0,
    },
    {
      label: 'Inativo',
      value: 1,
    },
  ],
};
