import React from 'react';
import { FornecedorProvider } from './contexts/FornecedorContext';
import FornecedorContent from './FornecedorContent';

const Fornecedor: React.FC = () => {
  return (
    <FornecedorProvider>
      <FornecedorContent />
    </FornecedorProvider>
  );
};
export default Fornecedor;
