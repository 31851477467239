export const Fretes = [
  {
    label: 'CIF (Fornecedor Responsável)',
    value: 0,
  },
  {
    label: 'FOB (Loja Responsável)',
    value: 1,
  },
];
