import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;


  @media screen and (max-width: 1199px) {
    .containerTipoPessoa{
      min-width: 250px !important;
    }
    .containerDocument{
      min-width: 18% !important;
    }
    .toggleFabricante{
      max-width: 13% !important;
    }
    .toggleExportador{
      max-width: 18% !important;
    }
    .toggleRegimeIe{
      max-width: 18% !important;
    }
    .toggleRegimeMei{
      max-width: 20% !important;
      min-width: 20% !important;
    }
    .toggleSubstituto{
      max-width:30% !important;
      min-width: 30% !important;
    }
    .toggleFrete{
      max-width:10% !important;
      min-width: 10% !important;
    }
    .toggleDespesa{
      max-width:20% !important;
      min-width: 20% !important;
    }

  }


  @media screen and (max-width: 991px) {
    .containerTipoPessoa{
      min-width: 50% !important;
    }

    .containerDocument{
      min-width: 50% !important;
    }

    .valorMinimoContainer{
      min-width: 52% !important;
    }

    .responsiveClass{
      min-width: 33% !important;
    }

    .toggleFabricante{
      max-width: 33% !important;
      min-width: 33% !important;
    }
    .toggleExportador{
      max-width: 33% !important;
      min-width: 33% !important;
    }
    .toggleRegimeIe{
      max-width: 33% !important;
      min-width: 33% !important;
    }
    .toggleRegimeMei{
      margin-top: 30px !important;
      max-width: 33% !important;
      min-width: 33% !important;
    }
    .toggleSubstituto{
      margin-top: 30px !important;
      max-width:50% !important;
      min-width: 50% !important;
    }


    .toggleDespesa{
      max-width:25% !important;
      min-width: 25% !important;
    }
    .toggleFrete{
      max-width:33% !important;
      min-width: 33% !important;
    }
    .toggleIndenizacao{
      max-width:33% !important;
      min-width: 33% !important;
    }
    .toggleDespesa{
      max-width:33% !important;
      min-width: 33% !important;
    }
    .toggleBonificacao{
      margin-top: 20px;
      max-width:33% !important;
      min-width: 33% !important;
    }
    .toggleAcrescimo{
      margin-top: 20px;
      max-width:33% !important;
      min-width: 33% !important;
    }
    .toggleIpi{
      margin-top: 20px;
      max-width:33% !important;
      min-width: 33% !important;
    }

  }

  @media screen and (max-width: 767px) {
    .toggleFabricante{
      max-width: 27% !important;
      min-width: 27% !important;
    }
    .toggleExportador{
      max-width: 33% !important;
      min-width: 33% !important;
    }
    .toggleRegimeIe{
      max-width: 40% !important;
      min-width: 40% !important;
    }
    .toggleRegimeMei{
      max-width: 40% !important;
      min-width: 40% !important;
    }
    .toggleSubstituto{
      max-width:60% !important;
      min-width: 60% !important;
    }

    .toggleFrete{
      max-width:50% !important;
      min-width: 50% !important;
    }
    .toggleIndenizacao{
      max-width:50% !important;
      min-width: 50% !important;
    }
    .toggleDespesa{
      margin-top: 20px;
      max-width:50% !important;
      min-width: 50% !important;
    }
    .toggleBonificacao{
      margin-top: 20px;
      max-width:50% !important;
      min-width: 50% !important;
    }
    .toggleAcrescimo{
      margin-top: 20px;
      max-width:50% !important;
      min-width: 50% !important;
    }
    .toggleIpi{
      margin-top: 20px;
      max-width:50% !important;
      min-width: 50% !important;
    }
  }
  @media screen and (max-width: 528px) {
    .toggleRegimeMei{
      max-width: 35% !important;
      min-width: 35% !important;
    }
  }


  /* input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[name='numCondicao'] {
    padding-right: 35px !important;
    padding-bottom: 4px !important;
    padding-top: 8px !important;
  }
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  } */
  .inputButton {
    float: right;
    height: 40px;
    line-height: 40px;
    margin-top: -40px;
    align-items: center;
    justify-content: center;
  }

  .btnLimiteCreditoFornecedor {
    float: right;
    padding: 8px 8px 8px 8px;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    /* margin-bottom: 5px; */
    transition: 0.4s;
    color: #424242;
  }
  .btnLimiteCreditoFornecedor:hover {
    background-color: #f4f5f7;
    color: #000;
    transition: 0.4s;
  }
  .accordion-collapse {
    border-color: transparent;
    border: none !important;
  }

  .containerSelect {
    @media screen and (max-width: 991px) {
      width: 40%;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }

  }

  .containerFinalizadora {
    @media screen and (max-width: 991px) {
      width: 43%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .numberContainer{
    max-width: 100px;
    padding-left: 0px;
    padding-right: 0px;
    @media screen and (max-width: 767px) {
      max-width: 100%;
      padding-right: calc(var(--bs-gutter-x)/ 2);
      padding-left: calc(var(--bs-gutter-x)/ 2);
    }
  }

  .condicaoButton {
    display: flex;
    button {
      width: 100%;
    }
  }
  .actButtonsCredito {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    @media screen and (min-width: 1000px){
      button {
        width: 100%;
      }
    }

    @media screen and (max-width: 1000px){
      .confirm {
          min-width:130px !important;
          height: 42px;
        }
        .cancel{
          min-width:130px !important;
          height: 42px;
        }
    }

    @media screen and (max-width: 991px){
      margin-right: 0px !important
      .confirm {
          min-width:100px !important;
        }
        .cancel{
          min-width:150px !important;
        }
    }
  }
  button:focus {
    outline: 0;
  }
  .btn-outline-danger:hover {
    background-color: #d94848;
    color: #fff;
  }
  .inputButton {
    float: right;
    height: 40px;
    line-height: 40px;
    margin-top: -40px;
    align-items: center;
    justify-content: center;
    /* justify-content: space-evenly; */
    .btn-outline-danger {
      border-color: #d4a9a9;
      color: #d94848;
    }
    button:nth-child(2) {
      border-color: #a9d4c0;
      color: #72ab90;
      margin-left: 10px;

      &:hover {
        background-color: #72ab90;
        color: #fff;
      }
    }
    button:focus {
      outline: 0;
    }
  }
  button:focus {
    outline: 0;
  }
  .btn-outline-danger:hover {
    background-color: #d94848;
    color: #fff;
  }
  .inputButton {
    float: right;
    height: 40px;
    line-height: 40px;
    margin-top: -40px;
    align-items: center;
    justify-content: center;
  }
  .input-container {
    position: relative;
    align-items: center;
    .min-button {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      right: 0;
      margin-right: 1px;
      margin-bottom: 0.0938rem;
      height: 2.425rem;
      width: 40px;
      background: #8850bf;
      border-radius: 0px 4px 4px 0px;
      &:hover {
        background: #57069e;
      }
      &:focus {
        background: #57069e;
      }
    }

    .form-control {
      padding: 0.375rem 0.75rem;
    }

    .form-control.is-invalid {
      background-image: none;
      border: 2px solid var(--ds-border-neutral, #dfe1e6);
      border-bottom: 2px solid #cf1919 !important ;
      &:focus {
        box-shadow: none;
      }
      padding: -0.375rem -0.75rem !important;
    }

    .min-button-loading {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      right: 0;
      height: 37px;
      width: 40px;
      background: #8850bf;
      border-radius: 0px 4px 4px 0px;
      cursor: not-allowed
      &:hover {
        background: #57069e;
      }
      &:focus {
        background: #57069e;
      }
    }

    @media screen and (max-width: 991px) {
      min-width: 50% !important;
    }
  }

  @media screen and (max-width: 1199px) {
    .containerCondicao {
      .containerFinalizadora {
        max-width: 234px;
      }
    }
  }
  .tipoCondicao {
    max-width: 300px;
  }

  @media screen and (max-width: 1199px){
    .tipoCondicao {
        max-width: 230px;
    }

    .buttonContainer{
      .confirm{
        min-width: 130px;
      }
      .cancel{
        min-width: 130px;
      }
    }
  }

  @media screen and (max-width: 991px){
    .buttonContainer{
      min-width: 100%;
    }
    .tipoCondicao {
      width: 40%;
    }
    .finalizadoraContainer{
      width: 41%;
    }
  }

  @media screen and (max-width: 767px) {
    .buttonContainer{
      width: 100%;
    }
    .tipoCondicao {
      max-width: 100%;
      width: 100%;
    }
    .finalizadoraContainer{
      width: 100%;
    }
  }
`;

export const ErrorMessage = styled.p`
  font-size: 11px;
  font-weight: 500;
  color: #db1212;
  margin-top: 2px;
`;

export const Label = styled.div`
  font-weight: 600;
  display: inline-block;
  margin-bottom: 2px;
  margin-top: 10px;
  margin-left: 0;
`;

export const Separator = styled.div`
  margin-top: 10px;
  padding: 0;

  p {
    color: #bdbdbd;
    width: 100%;
    text-align: start;
    border-bottom: 1px solid #ededed;
    line-height: 0.1em;
    margin: 10px 0 20px;
  }

  p span {
    background: #fff;
    padding: 0 10px;
  }
`;

export const ButtonRow = styled.button`
  border: none;
  background-color: transparent;
  color: #3d3d3d;
  &:nth-child(2) {
    margin-left: 10px;
  }
  &:hover {
    background-color: #f4f5f7 !important;
    color: #000;
    transition: 0.4s;
  }
`;
